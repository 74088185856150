:root {
  --plan-card-transparency: 0.1;
}

.primary {
  background-color: var(--md-sys-color-primary);
}

.primary-text {
  color: var(--md-sys-color-primary);
}

.on-primary {
  background-color: var(--md-sys-color-on-primary);
}

.on-primary-text {
  color: var(--md-sys-color-on-primary);
}

.primary-container {
  background-color: var(--md-sys-color-primary-container);
}

.primary-container-text {
  color: var(--md-sys-color-primary-container);
}

.on-primary-container {
  background-color: var(--md-sys-color-on-primary-container);
}

.on-primary-container-text {
  color: var(--md-sys-color-on-primary-container);
}

.secondary {
  background-color: var(--md-sys-color-secondary);
}

.secondary-text {
  color: var(--md-sys-color-secondary);
}

.on-secondary {
  background-color: var(--md-sys-color-on-secondary);
}

.on-secondary-text {
  color: var(--md-sys-color-on-secondary);
}

.secondary-container {
  background-color: var(--md-sys-color-secondary-container);
}

.secondary-container-text {
  color: var(--md-sys-color-secondary-container);
}

.on-secondary-container {
  background-color: var(--md-sys-color-on-secondary-container);
}

.on-secondary-container-text {
  color: var(--md-sys-color-on-secondary-container);
}

.on-primary-container-border {
  border: 1px solid var(--md-sys-color-on-primary-container);
}

.on-primary-container-border-top {
  border-top: 1px solid var(--md-sys-color-on-primary-container);
}

.on-secondary-container-border {
  border: 1px solid var(--md-sys-color-on-secondary-container);
}

.on-secondary-container-border-top {
  border-top: 1px solid var(--md-sys-color-on-secondary-container);
}

.on-tertiary-container-border {
  border: 1px solid var(--md-sys-color-on-tertiary-container);
}

.on-tertiary-container-border-top {
  border-top: 1px solid var(--md-sys-color-on-tertiary-container);
}

.tertiary {
  background-color: var(--md-sys-color-tertiary);
}

.tertiary-text {
  color: var(--md-sys-color-tertiary);
}

.on-tertiary {
  background-color: var(--md-sys-color-on-tertiary);
}

.on-tertiary-text {
  color: var(--md-sys-color-on-tertiary);
}

.tertiary-container {
  background-color: var(--md-sys-color-tertiary-container);
}

.tertiary-container-text {
  color: var(--md-sys-color-tertiary-container);
}

.on-tertiary-container {
  background-color: var(--md-sys-color-on-tertiary-container);
}

.on-tertiary-container-text {
  color: var(--md-sys-color-on-tertiary-container);
}

.error {
  background-color: var(--md-sys-color-error);
}

.error-text {
  color: var(--md-sys-color-error);
}

.on-error {
  background-color: var(--md-sys-color-on-error);
}

.on-error-text {
  color: var(--md-sys-color-on-error);
}

.error-container {
  background-color: var(--md-sys-color-error-container);
}

.error-container-text {
  color: var(--md-sys-color-error-container);
}

.on-error-container {
  background-color: var(--md-sys-color-on-error-container);
}

.on-error-container-text {
  color: var(--md-sys-color-on-error-container);
}

.outline {
  background-color: var(--md-sys-color-outline);
}

.outline-text {
  color: var(--md-sys-color-outline);
}

.background {
  background-color: var(--md-sys-color-background);
}

.background-text {
  color: var(--md-sys-color-background);
}

.on-background {
  background-color: var(--md-sys-color-on-background);
}

.on-background-text {
  color: var(--md-sys-color-on-background);
}

.surface {
  background-color: var(--md-sys-color-surface);
}

.surface-text {
  color: var(--md-sys-color-surface);
}

.on-surface {
  background-color: var(--md-sys-color-on-surface);
}

.on-surface-text {
  color: var(--md-sys-color-on-surface);
}

.surface-variant {
  background-color: var(--md-sys-color-surface-variant);
}

.surface-variant-text {
  color: var(--md-sys-color-surface-variant);
}

.on-surface-variant {
  background-color: var(--md-sys-color-on-surface-variant);
}

.on-surface-variant-text {
  color: var(--md-sys-color-on-surface-variant);
}

.inverse-surface {
  background-color: var(--md-sys-color-inverse-surface);
}

.inverse-surface-text {
  color: var(--md-sys-color-inverse-surface);
}

.inverse-on-surface {
  background-color: var(--md-sys-color-inverse-on-surface);
}

.inverse-on-surface-text {
  color: var(--md-sys-color-inverse-on-surface);
}

.inverse-primary {
  background-color: var(--md-sys-color-inverse-primary);
}

.inverse-primary-text {
  color: var(--md-sys-color-inverse-primary);
}

.shadow {
  background-color: var(--md-sys-color-shadow);
}

.shadow-text {
  color: var(--md-sys-color-shadow);
}

.surface-tint {
  background-color: var(--md-sys-color-surface-tint);
}

.surface-tint-text {
  color: var(--md-sys-color-surface-tint);
}

.outline-variant {
  background-color: var(--md-sys-color-outline-variant);
}

.outline-variant-text {
  color: var(--md-sys-color-outline-variant);
}

.scrim {
  background-color: var(--md-sys-color-scrim);
}

.scrim-text {
  color: var(--md-sys-color-scrim);
}

.discord {
  background-color: var(--wo-discord-background);
  border-color: var(--wo-discord-border);
  color: var(--wo-discord-text);
}

.apple {
  background-color: var(--wo-apple-background);
  border-color: var(--wo-apple-border);
  color: var(--wo-apple-text);
}

.facebook {
  background-color: var(--wo-facebook-background);
  border-color: var(--wo-facebook-border);
  color: var(--wo-facebook-text);
}

.github {
  background-color: var(--wo-github-background);
  border-color: var(--wo-github-border);
  color: var(--wo-github-text);
}

.google {
  background-color: var(--wo-google-background);
  border-color: var(--wo-google-border);
  color: var(--wo-google-text);
}

.microsoft {
  background-color: var(--wo-microsoft-background);
  border-color: var(--wo-microsoft-border);
  color: var(--wo-microsoft-text);
}

.twitter {
  background-color: var(--wo-twitter-background);
  border-color: var(--wo-twitter-border);
  color: var(--wo-twitter-text);
}

.instagram {
  background-color: var(--wo-instagram-background);
  border-color: var(--wo-instagram-border);
  color: var(--wo-instagram-text);
}

.threads {
  background-color: var(--wo-threads-background);
  border-color: var(--wo-threads-border);
  color: var(--wo-threads-text);
}

.flag-one {
  background-color: #000000;
}

.flag-two {
  background-color: #fdda24;
}

.flag-three {
  background-color: #ef3340;
}
