@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  background-color: var(--md-sys-color-surface);
}

::-webkit-scrollbar {
  width: 0.75em;
}

::-webkit-scrollbar-thumb {
  background-color: var(--md-sys-color-on-surface);
  border-radius: 100em;
  transition: background-color 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--md-sys-color-secondary);
}

::-webkit-scrollbar-horizontal {
  display: none;
}

.scrollbar-hidden::-webkit-scrollbar-thumb {
  background-color: var(--md-sys-color-surface);
}

html > div[style] {
  display: none !important;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: contain;
}

.leaflet-control-attribution {
  display: none !important;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  background-color: transparent;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  background: var(--md-sys-color-primary);
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  border-radius: 10px;
  background: var(--md-sys-color-on-primary);
  cursor: pointer;
  -webkit-appearance: none;
}

.leaflet-bar a {
  background-color: var(--md-sys-color-primary) !important;
  color: var(--md-sys-color-on-primary) !important;
  border-bottom-width: 0px !important;
}

.leaflet-bar a.leaflet-disabled {
  background-color: var(--md-sys-color-primary-container) !important;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 100px !important;
  border-top-right-radius: 100px !important;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
}

.leaflet-touch .leaflet-bar a {
  @apply transform;
}

.leaflet-touch .leaflet-bar a:first-child:not(.leaflet-disabled):hover {
  transform: scale(1.05) translateY(-1px);
}

.leaflet-touch .leaflet-bar a:last-child:not(.leaflet-disabled):hover {
  transform: scale(1.05) translateY(1px);
}

.leaflet-touch .leaflet-bar,
.leaflet-touch .leaflet-control-layers {
  border: 0px !important;
}

.leaflet-touch .leaflet-bar a {
  height: 3rem !important;
  line-height: 3rem !important;
  width: 3rem !important;
}

.leaflet-popup-content-wrapper {
  background: var(--md-sys-color-primary-container) !important;
  color: var(--md-sys-color-primary) !important;
  border-radius: 100px !important;
  box-shadow: 0 0px 0px #0000 !important;
}

.leaflet-container a.leaflet-popup-close-button {
  font: 0px/24px Tahoma, Verdana, sans-serif !important;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: var(--md-sys-color-primary-container) !important;
  box-shadow: 0 0px 0px #0000 !important;
}

.leaflet-popup-content {
  margin: 10px !important;
  display: flex !important;
  justify-content: center !important;
}
