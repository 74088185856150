:root {
  --md-sys-color-primary: var(--md-ref-palette-primary40);
  --md-sys-color-on-primary: var(--md-ref-palette-primary100);
  --md-sys-color-primary-container: var(--md-ref-palette-primary90);
  --md-sys-color-on-primary-container: var(--md-ref-palette-primary10);
  --md-sys-color-secondary: var(--md-ref-palette-secondary40);
  --md-sys-color-on-secondary: var(--md-ref-palette-secondary100);
  --md-sys-color-secondary-container: var(--md-ref-palette-secondary90);
  --md-sys-color-on-secondary-container: var(--md-ref-palette-secondary10);
  --md-sys-color-tertiary: var(--md-ref-palette-tertiary40);
  --md-sys-color-on-tertiary: var(--md-ref-palette-tertiary100);
  --md-sys-color-tertiary-container: var(--md-ref-palette-tertiary90);
  --md-sys-color-on-tertiary-container: var(--md-ref-palette-tertiary10);
  --md-sys-color-error: var(--md-ref-palette-error40);
  --md-sys-color-on-error: var(--md-ref-palette-error100);
  --md-sys-color-error-container: var(--md-ref-palette-error90);
  --md-sys-color-on-error-container: var(--md-ref-palette-error10);
  --md-sys-color-outline: var(--md-ref-palette-neutral-variant50);
  --md-sys-color-background: var(--md-ref-palette-neutral99);
  --md-sys-color-on-background: var(--md-ref-palette-neutral10);
  --md-sys-color-surface: var(--md-ref-palette-neutral99);
  --md-sys-color-on-surface: var(--md-ref-palette-neutral10);
  --md-sys-color-surface-variant: var(--md-ref-palette-neutral-variant90);
  --md-sys-color-on-surface-variant: var(--md-ref-palette-neutral-variant30);
  --md-sys-color-inverse-surface: var(--md-ref-palette-neutral20);
  --md-sys-color-inverse-on-surface: var(--md-ref-palette-neutral95);
  --md-sys-color-inverse-primary: var(--md-ref-palette-primary80);
  --md-sys-color-shadow: var(--md-ref-palette-primary0);
  --md-sys-color-surface-tint: var(--md-ref-palette-primary40);
  --md-sys-color-outline-variant: var(--md-ref-palette-neutral-variant80);
  --md-sys-color-scrim: var(--md-ref-palette-neutral0);
  --md-sys-color-primary-shadow-light: var(--md-ref-palette-primary95);
  --md-sys-color-primary-shadow-dark: var(--md-ref-palette-primary80);
  --md-sys-color-secondary-shadow-light: var(--md-ref-palette-secondary95);
  --md-sys-color-secondary-shadow-dark: var(--md-ref-palette-secondary80);
  --md-sys-color-tertiary-shadow-light: var(--md-ref-palette-tertiary95);
  --md-sys-color-tertiary-shadow-dark: var(--md-ref-palette-tertiary80);
  --md-sys-color-neutral-shadow-light: var(--md-ref-palette-neutral95);
  --md-sys-color-neutral-shadow-dark: var(--md-ref-palette-neutral80);
  --wo-shadow: 0, 0, 0;
  --wo-apple-text: #000;
  --wo-apple-background: #fff;
  --wo-apple-border: #8c8c8c;
  --wo-apple-logo: url("@template/Assets/apple_logo_light.svg");
  --wo-microsoft-text: #5e5e5e;
  --wo-microsoft-background: #fff;
  --wo-microsoft-border: #8c8c8c;
  --wo-microsoft-logo: url("@template/Assets/microsoft_logo.svg");
  --wo-google-text: #757575;
  --wo-google-background: #fff;
  --wo-google-border: #8c8c8c;
  --wo-google-logo: url("@template/Assets/google_logo.svg");
  --wo-facebook-text: #1877f2;
  --wo-facebook-background: #fff;
  --wo-facebook-border: #757575;
  --wo-facebook-logo: url("@template/Assets/facebook_logo_light.svg");
  --wo-github-text: #24292e;
  --wo-github-background: #fff;
  --wo-github-border: #757575;
  --wo-github-logo: url("@template/Assets/github_logo_light.svg");
  --wo-twitter-text: #1d9bf0;
  --wo-twitter-background: #fff;
  --wo-twitter-border: #757575;
  --wo-twitter-logo: url("@template/Assets/twitter_logo_light.svg");
  --wo-discord-text: #fff;
  --wo-discord-background: #5865f2;
  --wo-discord-border: #5865f2;
  --wo-discord-logo: url("@template/Assets/discord_logo.svg");
  --wo-threads-text: #000;
  --wo-threads-background: #fff;
  --wo-threads-border: #757575;
  --wo-threads-logo: url("@template/Assets/threads_logo_light.svg");
  --wo-instagram-text: #262626;
  --wo-instagram-background: #fff;
  --wo-instagram-border: #757575;
  --wo-instagram-logo: url("@template/Assets/instagram_logo_light.svg");

    --md-ref-palette-primary0: #000000;
    --md-ref-palette-primary100: #ffffff;
    --md-ref-palette-secondary100: #ffffff;
    --md-ref-palette-error40: #ba1a1a;
    --md-ref-palette-error80: #ffb4ab;
}

.neumorphic-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4), 0 -4px 6px rgba(255, 255, 255, 0.2);
}
