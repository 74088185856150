:root {
  --md-sys-color-primary: var(--md-ref-palette-primary80);
  --md-sys-color-on-primary: var(--md-ref-palette-primary20);
  --md-sys-color-primary-container: var(--md-ref-palette-primary30);
  --md-sys-color-on-primary-container: var(--md-ref-palette-primary90);
  --md-sys-color-secondary: var(--md-ref-palette-secondary80);
  --md-sys-color-on-secondary: var(--md-ref-palette-secondary20);
  --md-sys-color-secondary-container: var(--md-ref-palette-secondary30);
  --md-sys-color-on-secondary-container: var(--md-ref-palette-secondary90);
  --md-sys-color-tertiary: var(--md-ref-palette-tertiary80);
  --md-sys-color-on-tertiary: var(--md-ref-palette-tertiary20);
  --md-sys-color-tertiary-container: var(--md-ref-palette-tertiary30);
  --md-sys-color-on-tertiary-container: var(--md-ref-palette-tertiary90);
  --md-sys-color-error: var(--md-ref-palette-error80);
  --md-sys-color-on-error: var(--md-ref-palette-error20);
  --md-sys-color-error-container: var(--md-ref-palette-error30);
  --md-sys-color-on-error-container: var(--md-ref-palette-error90);
  --md-sys-color-outline: var(--md-ref-palette-neutral-variant60);
  --md-sys-color-background: var(--md-ref-palette-neutral10);
  --md-sys-color-on-background: var(--md-ref-palette-neutral90);
  --md-sys-color-surface: var(--md-ref-palette-neutral10);
  --md-sys-color-on-surface: var(--md-ref-palette-neutral90);
  --md-sys-color-surface-variant: var(--md-ref-palette-neutral-variant30);
  --md-sys-color-on-surface-variant: var(--md-ref-palette-neutral-variant80);
  --md-sys-color-inverse-surface: var(--md-ref-palette-neutral90);
  --md-sys-color-inverse-on-surface: var(--md-ref-palette-neutral10);
  --md-sys-color-inverse-primary: var(--md-sys-color-primary40);
  --md-sys-color-shadow: var(--md-ref-palette-primary0);
  --md-sys-color-surface-tint: var(--md-ref-palette-primary80);
  --md-sys-color-outline-variant: var(--md-ref-palette-neutral-variant30);
  --md-sys-color-scrim: var(--md-ref-palette-neutral-variant0);
  --md-sys-color-primary-shadow-light: var(--md-ref-palette-primary35);
  --md-sys-color-primary-shadow-dark: var(--md-ref-palette-primary25);
  --md-sys-color-secondary-shadow-light: var(--md-ref-palette-secondary35);
  --md-sys-color-secondary-shadow-dark: var(--md-ref-palette-secondary25);
  --md-sys-color-tertiary-shadow-light: var(--md-ref-palette-tertiary35);
  --md-sys-color-tertiary-shadow-dark: var(--md-ref-palette-tertiary25);
  --md-sys-color-neutral-shadow-light: var(--md-ref-palette-neutral35);
  --md-sys-color-neutral-shadow-dark: var(--md-ref-palette-neutral25);
  --wo-shadow: 90, 90, 90;
  --wo-apple-text: #fff;
  --wo-apple-background: #000;
  --wo-apple-border: #000;
  --wo-apple-logo: url("@template/Assets/apple_logo_dark.svg");
  --wo-microsoft-text: #fff;
  --wo-microsoft-background: #2f2f2f;
  --wo-microsoft-border: #2f2f2f;
  --wo-microsoft-logo: url("@template/Assets/microsoft_logo.svg");
  --wo-google-text: #fff;
  --wo-google-background: #4285f4;
  --wo-google-border: #4285f4;
  --wo-google-logo: url("@template/Assets/google_logo.svg");
  --wo-facebook-text: #fff;
  --wo-facebook-background: #1877f2;
  --wo-facebook-border: #1877f2;
  --wo-facebook-logo: url("@template/Assets/facebook_logo_dark.svg");
  --wo-github-text: #fff;
  --wo-github-background: #24292e;
  --wo-github-border: #24292e;
  --wo-github-logo: url("@template/Assets/github_logo_dark.svg");
  --wo-twitter-text: #fff;
  --wo-twitter-background: #1d9bf0;
  --wo-twitter-border: #1d9bf0;
  --wo-twitter-logo: url("@template/Assets/twitter_logo_dark.svg");
  --wo-discord-text: #fff;
  --wo-discord-background: #5865f2;
  --wo-discord-border: #5865f2;
  --wo-discord-logo: url("@template/Assets/discord_logo.svg");
  --wo-threads-text: #fff;
  --wo-threads-background: #000;
  --wo-threads-border: #000;
  --wo-threads-logo: url("@template/Assets/threads_logo_dark.svg");
  --wo-instagram-text: #fff;
  --wo-instagram-background: #e33e5c;
  --wo-instagram-border: #e33e5c;
  --wo-instagram-logo: url("@template/Assets/instagram_logo_dark.svg");

    --md-ref-palette-primary0: #000000;
    --md-ref-palette-primary100: #ffffff;
    --md-ref-palette-secondary100: #ffffff;
    --md-ref-palette-error40: #ba1a1a;
    --md-ref-palette-error80: #ffb4ab;
}

.neumorphic-shadow {
  box-shadow: 0 -4px 6px rgba(255, 255, 255, 0.1), 0 4px 6px rgba(0, 0, 0, 0.5);
}
